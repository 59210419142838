export default {
  // 英文
  word1001: 'English',
  word1002: 'E-mail',
  word1003: 'Password',
  word1004: 'Forget password？',
  word1005: 'Login',
  word1006: 'Register',
  word1007: 'User Agreement',
  word1008: 'Privacy',
  word1009: 'About Us',
  word1010: 'Account cannot be empty',
  word1011: 'Password cannot be empty',
  word1012: 'The account has been frozen',
  word1013: 'Account or password error',
  word1014: 'Successful Login',
  word1015: 'Nickname',
  word1016: 'Input numbers on the right',
  word1017: 'Get the Code',
  word1018: 'Repeat the password',
  word1019: 'Cannot be blank',
  word1020: 'Incorrect numbers',
  word1021: 'The entries do not match. Please re-enter',
  word1022: 'Cannot be blank',
  word1023: 'Cannot be blank',
  word1024: 'Cannot be blank',
  word1025: ' s to retry',
  word1026: 'Registration succeeded',
  word1027: 'The verification code from E-mail',
  word1028: 'Cannot be blank',
  word1029: 'New Password',
  word1030: 'Change Password',
  word1031: 'Cannot be blank',
  word1032: 'Modification Successful',
  word1033: 'Cross-border Business & Legal Communication System',
  word1034: 'Integrated Service System',
  word1035: 'Your account has been banned',
  word1036: 'Register and perform company verification',
  word1037: 'Company name',
  word1038: 'Company address',
  word1039: 'Company phone number',
  word1040: 'Position Held',
  word1041: 'Upload photo / scanned copy of Business License',
  word1042: 'JPG/PNG only, and must not exceed 20MB.',
  word1043: 'Upload photo / scanned copy of Tax Certificate',
  word1044: 'Submit',
  word1045: 'Skip verifying now, proceed to browse.',
  word1046: 'Image preview.',
  word1047: 'Upload photo / scanned copy of Business License',
  word1048: 'Upload photo / scanned copy of Tax Certificate',
  word1049: 'Please enter the company name.',
  word1050: 'Please enter the company address.',
  word1051: 'Company phone number cannot be empty',
  word1052: 'Please enter your occupation.',
  word1053: 'Deleted successfully.',
  word1054: 'Submitted successfully.',
  word1055: 'Sorry, this email has already been registered. Please use another email.',
  word1056: 'Register and skip company verification',
  word1057:
    'This system is designed specifically for cross-border connectivity, tailored for businesses to offer more commercial opportunities and legal resources. We recommend you undergo company verification to gain additional benefits',
  word1058: 'Notification',
  word1059: 'Skip verification',
  word1060: 'Company verification',
  word1061: 'User email already exists or has been deleted',
  word1062: 'Password length should be between 8 and 16 characters',
  word1063: 'Incorrect email format',
  word1064: 'Email verification code expired or not sent',
  word1065: 'Incorrect email verification code',

  // 湘企出海系统翻译
  word2001: 'Business Meets China Integrated Service System',
  word2002: 'Integrated Service System',
  word2003: 'Cross-border Business Interconnectivity System',
  word2004: 'The account has been disabled',

  // 新增
  AccountPlaceholder: 'Account Name',
  AccountEmptyTip: 'Please enter the account name',
  PasswordPlaceholder: 'Password',
  PasswordEmptyTip: 'Please enter the password',
  AccountPasswordError: 'Incorrect account name or password',
  RightNumber: 'Please enter the numbers on the right',
  NumberEmptyTip: 'Please enter the numbers on the right',
  EmailPlaceholder: 'Email',
  GetEmailCode: 'Get Verification Code',
  Retry: ' s to resend',
  Resend: 'Resend',
  EmailEmptyTip: 'Please enter the Email address',
  EmailCodePlaceholder: 'Please enter the Email verification code',
  EmailCodeEmptyTip: 'Email verification code cannot be empty',
  InputNewPassword: 'Please enter the new password',
  AgainInputNewPassword: 'Please re-enter your new password',
  AgainInputPassword: 'Please re-enter your password',
  Confirm: 'Confirm',
  RealName: 'Real Name',
  RealNameEmptyTip: 'Please enter your real name',
  EmailCodeInvalid: 'Invalid email verification code, please obtain again',
  RegisterCertify: 'Register and Verify',
  NotCertifyRegister: 'Register Directly Without Verification',
  NotCertifyTip:
    'This system is dedicated to cross-border interconnection, and we suggest you verify your company to enjoy more business opportunities and legal resources',
  GoCertify: 'Go to Verify',
  FileSize: 'JPG/PNG only, and must not exceed 20MB',
  AccountInexistence: 'The account does not exist, and the password cannot be modified.',
  DeleteTip: 'Your account has been deleted. Please contact your Chamber of Commerce administrator.',
  DearUser: 'Dear user,',
  NotShow: 'No longer displayed',
  PromptContentOne:
    'Hello, this system is an IT system designed for listed companies/prospective listed companies in China. It aggregates project information and legal resources from both domestic and international sources for use by enterprises. It is suitable for shareholders, general managers, marketing department heads, sales department heads, procurement department heads, and legal department heads of domestic listed companies/prospective listed companies. Please confirm that your company belongs to a listed company/intended listed company within China before registration, and truthfully fill in your personal information. Our backend management system will freeze or delete the associated accounts of non listed/proposed listed companies. Please be informed.',
  QuickPaymentTip:
    'Your chamber of commerce has set a membership fee payment. Please settle it promptly, or you will face consequences by [specific date]',
  UnableLoginTip: 'Unable to log in',
  Prompt: 'Prompt',
  MembershipDues: 'Pay membership fee',
  Determine: 'Confirm',
  MembershipExpirationTip: 'Your membership has expired. Please renew by paying the membership fee',
  Cancel: 'Cancel',
  ImgOnly: 'The image format can only be jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'The image size cannot exceed 2MB',
  UploadSuccess: 'Uploaded successfully'
}
