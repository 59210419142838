export default {
  // 葡萄牙语
  word1001: 'Portuguese',
  word1002: 'E-mail',
  word1003: 'Senha',
  word1004: 'Esqueceu a senha?',
  word1005: 'Entrar',
  word1006: 'Registrar-se',
  word1007: 'Contrato do Usuário',
  word1008: 'Privacidade Pessoal',
  word1009: 'Sobre Nós',
  word1010: 'A conta não pode ficar em branco',
  word1011: 'A senha não pode ficar em branco',
  word1012: 'A conta foi bloqueada.',
  word1013: 'Nome de usuário ou senha incorretos.',
  word1014: 'Login bem-sucedido.',
  word1015: 'Apelido',
  word1016: 'Insira o número à direita.',
  word1017: 'Obtenha o código de verificação.',
  word1018: 'Repita a senha.',
  word1019: 'O número não pode ficar em branco.',
  word1020: 'Código de verificação inválido.',
  word1021: 'As senhas não coincidem.',
  word1022: 'O apelido não pode ficar vazio',
  word1023: 'O e-mail não pode ficar vazio',
  word1024: 'A confirmação de senha não pode ficar vazia',
  word1025: 'Tente novamente em {segundos}',
  word1026: 'Cadastro bem-sucedido',
  word1027: 'Código de verificação de e-mail',
  word1028: 'O código de verificação não pode ficar vazio',
  word1029: 'Nova senha',
  word1030: 'Alterar senha',
  word1031: 'A nova senha não pode ficar vazia',
  word1032: 'Alteração bem-sucedida',
  word1033: 'Sistema de Comércio Transfronteiriço & Integração Jurídica',
  word1034: 'Sistema de Serviços Integrados',
  word1035: 'Sua conta foi desativada',
  word1036: 'Registre-se e realize a certificação da empresa',
  word1037: 'Nome da empresa',
  word1038: 'Endereço da empresa',
  word1039: 'Telefone da empresa',
  word1040: 'Cargo atual na empresa',
  word1041: 'Envie o documento de registro comercial',
  word1042: 'Apenas arquivos JPG/PNG podem ser enviados, com tamanho não superior a 20MB',
  word1043: 'Carregar Certificado Fiscal',
  word1044: 'Submeter',
  word1045: 'Não validar por enquanto, navegue primeiro',
  word1046: 'Pré-visualização de imagem',
  word1047: 'Por favor, carregue o documento de registro da empresa',
  word1048: 'Por favor, carregue o certificado fiscal',
  word1049: 'O nome da empresa não pode estar em branco',
  word1050: 'O endereço da empresa não pode estar em branco',
  word1051: 'O número de telefone da empresa não pode estar vazio.',
  word1052: 'A posição atual no cargo não pode ficar em branco.',
  word1053: 'Exclusão bem-sucedida.',
  word1054: 'Envio bem-sucedido.',
  word1055: 'Desculpe, este endereço de e-mail já está cadastrado. Por favor, use outro endereço de e-mail.',
  word1056: 'Não estamos realizando a verificação da empresa no momento do registro.',
  word1057:
    'Este sistema é projetado especificamente para integração transfronteiriça, personalizado para empresas, oferecendo mais oportunidades comerciais e recursos legais. Recomendamos que você realize a certificação da sua empresa para obter mais benefícios.',
  word1058: 'Aviso',
  word1059: 'Não certificado por enquanto',
  word1060: 'Certificação da Empresa',
  word1061: 'O endereço de e-mail do usuário já existe ou foi excluído.',
  word1062: 'A senha deve ter entre 8 e 16 caracteres.',
  word1063: 'Formato de e-mail inválido.',
  word1064: 'Código de verificação do e-mail expirou ou não foi enviado.',
  word1065: 'Código de verificação do e-mail incorreto.',

  // 湘企出海系统翻译
  word2001: 'O Comércio Encontra a China',
  word2002: 'Sistema Integrado de Serviços',
  word2003: 'Sistema de Intercâmbio Comercial Transfronteiriço',
  word2004: 'Esta conta foi desativada.',

  // 新增
  AccountPlaceholder: 'E-mail',
  AccountEmptyTip: 'O campo da conta de e-mail não pode ficar vazio.',
  PasswordPlaceholder: 'Senha',
  PasswordEmptyTip: 'O campo da senha não pode ficar vazio.',
  AccountPasswordError: 'Nome de usuário ou senha incorretos.',
  RightNumber: 'Por favor, insira os números na imagem à direita.',
  NumberEmptyTip: 'Os números na imagem não podem estar em branco.',
  EmailPlaceholder: 'E-mail',
  GetEmailCode: 'Obter código de verificação do e-mail',
  Retry: ' Tente novamente em alguns segundos.',
  Resend: 'Reenviar',
  EmailEmptyTip: 'O campo de conta de e-mail não pode ficar em branco.',
  EmailCodePlaceholder: 'Código de verificação do e-mail',
  EmailCodeEmptyTip: 'O código de verificação do e-mail não pode ficar em branco.',
  InputNewPassword: 'Por favor, insira uma nova senha.',
  AgainInputNewPassword: 'Por favor, digite a nova senha novamente.',
  AgainInputPassword: 'Por favor, insira a senha novamente',
  Confirm: 'Confirmar',
  RealName: 'Nome real',
  RealNameEmptyTip: 'O nome real não pode ficar em branco.',
  EmailCodeInvalid: 'O código de verificação do e-mail é inválido. Por favor, obtenha um novo.',
  RegisterCertify: 'Registre-se e realize a autenticação.',
  NotCertifyRegister: 'Não realizar autenticação por enquanto, registrar diretamente.',
  NotCertifyTip:
    'Este sistema é especializado em interconexões transfronteiriças e recomenda-se que você realize a autenticação da sua empresa para aproveitar mais oportunidades comerciais e recursos legais.',
  GoCertify: 'Ir para autenticação.',
  FileSize: 'Faça o upload de arquivos no formato .jpg ou .png, com tamanho máximo de 20MB.',
  AccountInexistence: 'Esta conta não existe e não é possível alterar a senha.',
  DeleteTip: 'Sua conta foi excluída. Por favor, entre em contato com o administrador da sua Câmara de Comércio.',
  DearUser: 'Prezado Usuário,',
  NotShow: 'Não será mais exibido',
  PromptContentOne:
    'Olá, este sistema foi especialmente criado para empresas listadas na China e empresas candidatas à listagem, atuando como uma plataforma de TI que integra informações de projetos e recursos legais tanto dentro como fora da China, visando o aproveitamento destas informações pelas empresas mencionadas. É indicado para acionistas, presidentes-executivos, responsáveis pelo setor de marketing, chefes de vendas, gerentes de compras e chefes de departamentos jurídicos de empresas listadas ou que buscam listagem na China. Antes de prosseguir com o registro, verifique se sua empresa se enquadra como uma empresa listada ou em processo de listagem na China e preencha suas informações pessoais de forma verdadeira. Nosso sistema de gestão interna irá congelar ou remover contas relacionadas a empresas que não sejam listadas ou candidatas à listagem. Por favor, tenha ciência disso.',
  QuickPaymentTip:
    'Sua câmara de comércio definiu o pagamento das taxas de associação. Por favor, efetue o pagamento o mais rápido possível, caso contrário, você enfrentará consequências em [data específica]',
  UnableLoginTip: 'Não consigo fazer login',
  Prompt: 'Aviso',
  MembershipDues: 'Pagar taxa de associacao',
  Determine: 'Confirmar',
  MembershipExpirationTip: 'Sua adesão expirou. Por favor, renove pagando a taxa de associação',
  Cancel: 'Cancelar',
  ImgOnly: 'O formato da imagem só pode ser jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'O tamanho da imagem não pode exceder 2MB',
  UploadSuccess: 'Carregamento bem-sucedido'
}
