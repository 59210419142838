import Vue from 'vue'
import VueI18n from 'vue-i18n' // 引入 i18n 模块
import locale from 'element-ui/lib/locale' // elementUI语言包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // elementUI中文包
import elementEnLocale from 'element-ui/lib/locale/lang/en' // elementUI英文包
import elementIdLocale from 'element-ui/lib/locale/lang/id' // elementUI印尼语包
import elementMalayLocale from 'element-ui/lib/locale/lang/en' // elementUI马来语包(暂时用英文代替)
import elementThLocale from 'element-ui/lib/locale/lang/th' // elementUI泰语包
import elementViLocale from 'element-ui/lib/locale/lang/vi' // elementUI越南语包
import elementBurmeseLocale from 'element-ui/lib/locale/lang/en' // elementUI缅甸语包(暂时用英文代替)
import elementLaoLocale from 'element-ui/lib/locale/lang/en' // elementUI老挝语包(暂时用英文代替)
import elementKmLocale from 'element-ui/lib/locale/lang/km' // elementUI高棉语包
import elementFilipinoLocale from 'element-ui/lib/locale/lang/en' // elementUI菲律宾语包(暂时用英文代替)
import elementPtLocale from 'element-ui/lib/locale/lang/pt' // elementUI葡萄牙语包
import zhLocale from './zh.js' // 本地中文包
import enLocale from './en.js' // 本地英文包
import bahasaLocale from './bahasa.js' // 本地印尼语言包
import malayLocale from './malay.js' // 本地马来语言包
import thaiLocale from './thai.js' // 本地泰语言包
import vietnameseLocale from './vietnamese.js' // 本地越南语言包
import burmeseLocale from './burmese.js' // 本地缅甸语言包
import laoLocale from './lao.js' // 本地老挝语言包
import khmerLocale from './khmer.js' // 本地高棉语言包
import filipinoLocale from './filipino.js' // 本地菲律宾语言包
import portugueseLocale from './portuguese.js' // 本地葡萄牙语言包

Vue.use(VueI18n)

const messages = {
  'zh-CN': {
    ...zhLocale,
    ...elementZhLocale
  },
  'en-US': {
    ...enLocale,
    ...elementEnLocale
  },
  'bahasa-Indonesia': {
    ...bahasaLocale,
    ...elementIdLocale
  },
  Malay: {
    ...malayLocale,
    ...elementMalayLocale
  },
  Thai: {
    ...thaiLocale,
    ...elementThLocale
  },
  Vietnamese: {
    ...vietnameseLocale,
    ...elementViLocale
  },
  Burmese: {
    ...burmeseLocale,
    ...elementBurmeseLocale
  },
  Lao: {
    ...laoLocale,
    ...elementLaoLocale
  },
  Khmer: {
    ...khmerLocale,
    ...elementKmLocale
  },
  Filipino: {
    ...filipinoLocale,
    ...elementFilipinoLocale
  },
  Portuguese: {
    ...portugueseLocale,
    ...elementPtLocale
  }
}

const language =
  sessionStorage.getItem('changeChange') == 'yes'
    ? sessionStorage.getItem('locale')
      ? sessionStorage.getItem('locale')
      : 'zh-CN'
    : 'zh-CN'

// 创建国际化实例
const i18n = new VueI18n({
  locale: language, // 默认中文
  messages
})
locale.i18n((key, value) => i18n.t(key, value))

export default i18n
