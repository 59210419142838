export default {
  // 菲律宾语
  word1001: 'Filipino',
  word1002: 'Email',
  word1003: 'Password',
  word1004: 'Nakalimutan ang password?',
  word1005: 'Mag-sign in',
  word1006: 'Magparehistro',
  word1007: 'User Agreement',
  word1008: 'Pagkapribado ng Indibidwal',
  word1009: 'Tungkol sa Amin',
  word1010: 'Hindi maaaring walang laman ang account',
  word1011: 'Hindi maaaring walang laman ang password',
  word1012: 'Ang account ay naka-freeze na.',
  word1013: 'Maling pangalan ng account o password.',
  word1014: 'Matagumpay na pag-login.',
  word1015: 'Pangalang screen',
  word1016: 'Ipasok ang numero sa kanan.',
  word1017: 'Kumuha ng verification code.',
  word1018: 'Ulitin ang password.',
  word1019: 'Hindi maaaring walang laman ang mga numero.',
  word1020: 'Mali ang verification code.',
  word1021: 'Hindi magkapareho ang dalawang password.',
  word1022: 'Hindi pwedeng walang pangalan ng tagapagamit',
  word1023: 'Hindi pwedeng walang email',
  word1024: 'Hindi pwedeng walang pag-ulit ng password',
  word1025: 'Subukang muli sa {segundo/s}',
  word1026: 'Matagumpay na rehistrasyon',
  word1027: 'Kodigo ng verifikasiyon ng email',
  word1028: 'Hindi pwedeng walang kodigo ng verifikasiyon',
  word1029: 'Bagong password',
  word1030: 'I-edit ang password',
  word1031: 'Hindi pwedeng walang bagong password',
  word1032: 'Matagumpay na nai-update',
  word1033: 'Sistema ng Paggamit sa Pandaigdigang mga Transaksyon at Pamamahala sa Legalidad',
  word1034: 'Pangkalahatang Sistema ng Serbisyo',
  word1035: 'Ang iyong account ay hindi na pinapagana',
  word1036: 'Magrehistro at magpatunay ng kumppanya',
  word1037: 'Paggamit ng pangalan ng kumpanya',
  word1038: 'Address ng kumpanya',
  word1039: 'Telepono ng kumpanya',
  word1040: 'Trabaho o posisyon sa kasalukuyan',
  word1041: 'I-upload ang lisensya ng pag-aari',
  word1042: 'Maaaring i-upload lamang mga file ng tipo JPG/PNG, at hindi dapat higit sa 20MB',
  word1043: 'I-upload ang Papeles sa Pagbabayad ng Buwis',
  word1044: 'Magsumite',
  word1045: 'Hindi muna magpapatunay, unang mag-browse muna',
  word1046: 'Preview ng Larawan',
  word1047: 'Mangyaring i-upload ang Lisensya ng Negosyo',
  word1048: 'Mangyaring i-upload ang mga Papeles sa Pagbabayad ng Buwis',
  word1049: 'Ang pangalan ng kumpanya ay hindi dapat walang laman',
  word1050: 'Ang address ng kumpanya ay hindi dapat walang laman',
  word1051: 'Ang numero ng telepono ng kumpanya ay dapat magkaroon ng laman.',
  word1052: 'Ang posisyon sa kasalukuyang pag-aasawa ay dapat magkaroon ng laman.',
  word1053: 'Matagumpay na nabawasan.',
  word1054: 'Matagumpay na nai-submit.',
  word1055:
    'Paumanhin, ang email address na ito ay mayroon nang rehistrado. Mangyaring gamitin ang ibang email address.',
  word1056: 'Hindi pa ginagamit ang pagpapakilala ng kumpanya sa panahon ng pagsusumite ng form.',
  word1057:
    'Ang sistema na ito ay nakatuon sa pangkalahatang transaksyon sa pagitan ng mga bansa at tinailor-fit para sa bawat kumpanya upang mabigyan ng higit pang mga oportunidad sa negosyo at mga mapagkukunan sa batas. Inirerekumenda naming gawin ang pagpapakilala ng kumpanya upang makakuha ng higit pang mga benepisyo.',
  word1058: 'Reminder',
  word1059: 'Hindi pa nasisertipiko ngayon',
  word1060: 'Pagpapakilala ng kumpanya',
  word1061: 'Ang email address ng user ay umiiral na o natanggal na.',
  word1062: 'Dapat ang haba ng password ay nasa pagitan ng 8 hanggang 16 character.',
  word1063: 'May mali sa format ng email.',
  word1064: 'Ang code ng confirmation ng email ay hindi na wasto o hindi pa ipinadala.',
  word1065: 'Mali ang code ng confirmation ng email.',

  // 湘企出海系统翻译
  word2001: 'Negosyo sa Takdanan ng Tsina',
  word2002: 'Komprehensibong sistema ng serbisyo',
  word2003: 'Sistema ng Pagsasakatuparan ng mga Pandaigdigang Palitan sa Business',
  word2004: 'Ang account na ito ay hindi na pinagana.',

  // 新增
  AccountPlaceholder: 'Email',
  AccountEmptyTip: 'Hindi dapat walang laman ang account ng email.',
  PasswordPlaceholder: 'Password',
  PasswordEmptyTip: 'Hindi dapat walang laman ang password.',
  AccountPasswordError: 'Mali ang account o password.',
  RightNumber: 'Mangyaring i-type ang mga numero sa larawan sa kanang bahagi.',
  NumberEmptyTip: 'Hindi dapat walang laman ang mga numero sa larawan.',
  EmailPlaceholder: 'Email',
  GetEmailCode: 'Kunin ang code ng confirmation ng email.',
  Retry: ' Subukang muli sa ilang segundo.',
  Resend: 'I-re-send.',
  EmailEmptyTip: 'Ang account ng email ay dapat magkaroon ng laman.',
  EmailCodePlaceholder: 'Kode ng confirmation ng email.',
  EmailCodeEmptyTip: 'Ang kode ng confirmation ng email ay dapat magkaroon ng laman.',
  InputNewPassword: 'Mangyaring i-type ang bagong password.',
  AgainInputNewPassword: 'Mangyaring i-type muli ang bagong password.',
  AgainInputPassword: 'Pakiusap, sumulat muli ng iyong password.',
  Confirm: 'Sigurado.',
  RealName: 'Tunay na pangalan.',
  RealNameEmptyTip: 'Ang tunay na pangalan ay dapat magkaroon ng laman.',
  EmailCodeInvalid: 'Hindi wasto ang kode ng confirmation ng email, mangyaring makuha muli.',
  RegisterCertify: 'Magrehistro at magpatunay.',
  NotCertifyRegister: 'Hindi pa magpapatunay ngayon, direktang magrehistro.',
  NotCertifyTip:
    'Ang sistema na ito ay nakatuon sa pangkalahatang pag-aakses sa pagitan ng mga bansa at inirerekumenda namin na gawin ang pagsusumite ng kumpirmasyon para sa iyong negosyo upang makamit ang higit pang mga oportunidad sa negosyo at mapagkukunan sa batas.',
  GoCertify: 'Pumunta sa pagpapatunay.',
  FileSize:
    'Mangyaring i-upload ang isang file sa format na .jpg o .png, at ang sukat ng file ay hindi dapat higit sa 20MB.',
  AccountInexistence: 'Ang account na ito ay hindi umiiral, kaya hindi maaaring baguhin ang password.',
  DeleteTip:
    'Ang iyong account ay na-delete na. Mangyaring makipag-ugnay sa iyong tagapamahala ng kapisanan ng mga negosyante.',
  DearUser: 'Mapagpalang gumagamit,',
  NotShow: 'Hindi na magpakita',
  PromptContentOne:
    'Kumusta, ang sistema na ito ay nilikha para sa mga publikong kumpanyang nakalista/nakatakdang maglistahan sa loob ng Tsina, upang magtipon-tipon ng impormasyon ukol sa mga proyekto mula sa loob at labas ng Tsina, pati na rin mga legal na resource, na maaaring gamitin ng mga kumpanya. Ito ay angkop para sa mga stockholder, punong ekutive, hepe ng marketing, hepe ng pagbebenta, hepe ng pag-aakuso, at hepe ng legal ng mga publikong kumpanyang nakalista sa loob ng Tsina/nakatakdang maglistahan. Bago ka magrehistro, mangyaring tiyaking kasapi ka ng isang publikong kumpanyang nakalista sa loob ng Tsina/nakatakdang maglistahan at maayos na isulat ang iyong personal na impormasyon. Ang aming sistema ng pamamahala sa likod ng entablado ay maglilimos ng pagkakasara o pagtanggal ng mga konektadong account na hindi publikong kumpanyang nakalista/nakatakdang maglistahan. Paki-alaman lamang po ito.',
  QuickPaymentTip:
    'Ang iyong kamara ng komersyo ay nakatakdang magbayad ng bayad sa miyembro. Mangyaring ayusin ito nang maaga, kung hindi, ikaw ay magkakaroon ng mga konsekuensiya sa [takdang petsa]',
  UnableLoginTip: 'Hindi makalog-in',
  Prompt: 'Babala',
  MembershipDues: 'Magbayad ng bayad sa miyembro',
  Determine: 'Tiyakin',
  MembershipExpirationTip:
    'Natapos na ang iyong pagiging miyembro. Mangyaring magbayad ng bayad sa miyembro upang marenew',
  Cancel: 'Kanselahin',
  ImgOnly: 'Ang format ng larawan ay maaari lamang maging jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'Huwag maaabot ang laki ng imahe na higit sa 2MB',
  UploadSuccess: 'Matagumpay ang pag-upload'
}
